import { Route, Routes, useLocation, useNavigate } from "react-router";
import Home from "./components/Home/Home.component.jsx";
import { AuthContextProvider } from "./context/AuthContext.jsx";
import Register from "./components/Register/Register.jsx";
import Login from "./components/Login/Login.jsx";
import api from "./api/api.js";
import { useEffect, useState } from "react";
import Hub from "./components/Hub/Hub.component.jsx";
import DashBoard from "./components/DashBoard/DashBoard.component.jsx";
import UserNavigation from "./components/Navigation/UserNavigation/UserNavigation.component.jsx";
import HubManagement from "./components/HubManagement/HubManagement.component.jsx";
import InviteRequestViewer from "./components/InviteRequestViewer/InviteRequestViewer.component.jsx";
import Settings from "./components/Settings/Settings.component.jsx";
import AssignmentDetailsPage from "./components/AssignmentDetailsPage/AssignmentDetailsPage.component.jsx";
import { ConfigProvider, Spin } from "antd";
import { ForgotPassword } from "./components/ForgotPassword/ForgotPassword.component.jsx";
import Delete from "./Pages/Delete/Delete.jsx";
import Privacy from "./Pages/Privacy/Privacy.jsx";
import "./App.scss";
import DeleteUser from "./components/DeleteUser/DeleteUser.component.jsx";
import Support from "./Pages/Support/Support.jsx";
import EditProfile from "./components/EditProfile/EditProfile.component.jsx";
import HubProfileDetailsComponent from "./components/HubProfileDetails/HubProfileDetails.component.jsx";
import CrewProfileView from "./components/CrewProfileView/CrewProfileView.component.jsx";
import BlogBook from "./Pages/Blogs/src/app/index.js";
import UserProvider from "./context/UserContext.js";
import { routes } from "./Pages/Blogs/src/app/router/routes.js";
import CreateBlog from "./Pages/Blogs/src/app/pages/CreateBlog/index.js";
import { Provider } from "react-redux";
import { store } from "./Pages/Blogs/src/store/index.js";
import BlogLogin from "./Pages/Blogs/src/app/pages/Home/Login.js";
import Signup from "./Pages/Blogs/src/app/pages/Home/Signup.js";
import Feed from "./Pages/Blogs/src/app/pages/Feed/index.js";
import Profile from "./Pages/Blogs/src/app/pages/Profile/index.js";
import BlogSettings from "./Pages/Blogs/src/app/pages/Settings/index.js";
import ReadBlog from "./Pages/Blogs/src/app/pages/ReadBlog/index.js";
import EditBlog from "./Pages/Blogs/src/app/pages/EditBlog/index.js";
import Error404 from "./Pages/Blogs/src/app/pages/Results/404.js";
import PublicReadBlog from "./Pages/Blogs/src/app/pages/PublicReadBlog/PublicReadBlog.jsx";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const customTheme = {
    token: {
      colorPrimary: "#22aade",
    },

    components: {
      Button: {
        colorPrimary: "#22aade",
        colorPrimaryBorder: "#22aade",
        colorText: "#ffffff",
        colorBgContainer: "#353542",
        defaultBorderColor: "#353542",
        defaultColor: "#ffffff",
        defaultHoverColor: "#ffffff",
        defaultHoverBorderColor: "#353542",
      },
      Input: {
        borderRadius: 16,
        colorBorder: "#353542",
        colorTextPlaceholder: "#A3A1A1",
        colorText: "#ffffff",
        colorBgContainer: "#1e1e1e",
      },
      Divider: {
        colorTextHeading: "#ffffff",
        colorSplit: "#A3A1A1",
      },
      Upload: {
        colorText: "#ffffff",
      },
      Progress: {
        defaultColor: "#22aade",
        remainingColor: "#353542",
      },
      Tabs: {
        colorText: "#ffffff",
      },
      Radio: {
        buttonBg: "transparent",
        buttonCheckedBg: "transparent",
        buttonColor: "#ffffff",
      },
    },
  };
  const publicRoutes = ["/delete", "/privacy", "/support", "/blog", "/blogs"];

  // Save the current route to localStorage
  useEffect(() => {
    localStorage.setItem("lastRoute", location.pathname);
  }, [location.pathname]);

  const checkLogin = async () => {
    try {
      const publicBlogRouteRegex = /^\/blog\/[a-zA-Z0-9]+\/[a-zA-Z0-9-]+$/;
      if (
        publicRoutes.includes(location.pathname) ||
        publicBlogRouteRegex.test(location.pathname)
      ) {
        // If the current route is a public route, skip the login check
        setLoading(false);
        return;
      }
      const res = await api.getCurrentUser();
      console.log(res);
      if (res) {
        const lastRoute = localStorage.getItem("lastRoute");
        if (
          lastRoute &&
          (lastRoute.startsWith(`/user/${res.crew_id}`) ||
            lastRoute.startsWith(`/blog`) ||
            lastRoute.startsWith(`/writer`) ||
            lastRoute.startsWith(`/settings`))
        ) {
          navigate(lastRoute);
        } else {
          res?.hub
            ? navigate(`/user/${res.crew_id}`)
            : navigate(`/user/${res.crew_id}/hub`);
        }
      } else {
        navigate(`/`);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // After checking, set loading to false
    }
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <UserProvider>
            <AuthContextProvider>
              <ConfigProvider theme={customTheme}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="register" element={<Register />} />
                  <Route path="login" element={<Login />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="delete" element={<Delete />} />

                  <Route path="privacy" element={<Privacy />} />
                  <Route path="support" element={<Support />} />
                  <Route path="user" element={<UserNavigation />}>
                    <Route index path="/user/:userId" element={<DashBoard />} />
                    <Route path="/user/:userId/hub" element={<Hub />} />
                    <Route
                      path="/user/:userId/hub/:hubId"
                      element={<HubManagement />}
                    />
                    <Route
                      path="/user/:userId/assignment/:assignmentId"
                      element={<AssignmentDetailsPage />}
                    />
                    <Route
                      path="/user/:userId/hub/invite-request-management"
                      element={<InviteRequestViewer />}
                    />
                    <Route
                      path="/user/:userId/hub/delete-user"
                      element={<DeleteUser />}
                    />
                    <Route
                      path="/user/:userId/settings"
                      element={<Settings />}
                    />
                    <Route
                      path="/user/:userId/edit-profile/"
                      element={<EditProfile />}
                    />
                    <Route
                      path="/user/:userId/edit-hub/"
                      element={<EditProfile />}
                    />
                    <Route
                      path="/user/:userId/hub-view/:hubId/"
                      element={<HubProfileDetailsComponent />}
                    />
                    <Route
                      path="/user/:userId/profile-view/:crewId/"
                      element={<CrewProfileView />}
                    />
                  </Route>
                </Routes>
              </ConfigProvider>
              <Provider store={store}>
                <Routes>
                  <Route path="blog/login" element={<BlogLogin />} />
                  <Route path="blog/signup" element={<Signup />} />
                  <Route path="blog/feed/" element={<Feed />} />
                  <Route path={routes.CREATE_BLOG} element={<CreateBlog />} />
                  <Route
                    path={routes.PROFILE(":userId")}
                    element={<Profile />}
                  />
                  <Route path={routes.SETTINGS} element={<BlogSettings />} />
                  <Route
                    exact
                    path={routes.READ_BLOG(":blogId")}
                    element={<ReadBlog />}
                  />
                  <Route
                    exact
                    path="blog/:blogId/:blogTitle"
                    element={<PublicReadBlog />}
                  />
                  <Route
                    exact
                    path={routes.EDIT_BLOG(":blogId")}
                    element={<EditBlog />}
                  />
                </Routes>
              </Provider>
            </AuthContextProvider>
          </UserProvider>
        </>
      )}
    </>
  );
};

export default App;
