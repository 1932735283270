const ENV = process.env.NODE_ENV;

const API_URL =
  window.location.hostname === "cohubitate.com"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_API_URL;

const development = {
  ENV: "development",
  BASE_API_URL: "http://localhost:8000",
};

const production = {
  ...development,
  ENV: "production",
  BASE_API_URL: API_URL,
};

const configs = { production, development };

const currentConfig = configs[ENV];

export const config = {
  ENV: currentConfig.ENV,
  BASE_API_URL: currentConfig.BASE_API_URL,
};
