export const initialState = {
  user: { name: undefined },
  userId: -1,
  refreshToken: "",
  authToken: "",
  isLoggedIn: false,
  portalErr: "",
  portalMsg: "",
  isSubmitting: "",
};
