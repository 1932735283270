import "./index.css";
import { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "../../components";
import { Link, useHistory, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../router/routes";
import {
  resetStore,
  setTitle,
  setContent,
  setSubmitType,
  handleBlog,
  getBlogData,
} from "../../../store/blog/actions";
import { UpdateBlogSkeleton } from "../../skeletons";
import { userStorage } from "../../../utils";

const UpdateBlog = () => {
  const [submitType, setSubmitTypeState] = useState("");
  const user = userStorage.getUser();
  const { blogId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(getBlogData(blogId));
    return () => {
      dispatch(resetStore());
    };
  }, [dispatch, blogId]);

  // const handle403 = () => {
  //   message.error("You can only edit your own blogs!");
  //   history(routes.READ_BLOG(blogId));
  // };

  const handleSubmit = () => {
    dispatch(handleBlog(blogId, "update", submitType, navigate));
  };

  if (blog.loading) {
    return <UpdateBlogSkeleton />;
  }

  return (
    <div className="blog-portal-wrapper">
      <Navbar feed profile logout />
      <div className="blog-portal">
        <h2 className="blog-portal-head">Edit Blog</h2>
        <Form
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          key={!blog?.titleChanged && blog?.title}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                min: 1,
                max: 40,
                message: "Title must be 1-40 characters",
              },
            ]}
          >
            <Input
              defaultValue={blog?.title}
              onChange={(e) => dispatch(setTitle(e.target.value))}
              size="large"
              placeholder="Type your title here..."
            />
          </Form.Item>

          <Form.Item label="Content">
            <Editor
              editorState={blog.content}
              onEditorStateChange={(val) => dispatch(setContent(val))}
              wrapperClassName="richWrapper"
              editorClassName="richEditor"
              toolbarClassName="richToolbar"
              placeholder="Type your content here..."
            />
          </Form.Item>

          <Form.Item>
            <footer className="createBlog-footer">
              <div className="blog-portal-nav">
                <Button
                  className="archive-btn"
                  htmlType="submit"
                  loading={blog.submitType === "archive"}
                  disabled={blog.submitType === "publish"}
                  type="ghost"
                  size="large"
                  onClick={() => setSubmitTypeState("archive")}
                >
                  Archive
                </Button>
                <Button
                  htmlType="submit"
                  loading={blog.submitType === "publish"}
                  disabled={blog.submitType === "archive"}
                  type="primary"
                  size="large"
                  onClick={() => setSubmitTypeState("publish")}
                >
                  Publish
                </Button>
              </div>
              <Link to={routes.READ_BLOG(blogId)}>Cancel</Link>
            </footer>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateBlog;
