import "./index.css";
import { useState, useEffect } from "react";
import { Dropdown, Row, Tabs, Tag } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import defaultDp from "../../../../../../assets/images/profile.png";
import { Banner, Navbar } from "../../components";
import { FeedSkeleton } from "../../skeletons";
import { ellipsis, userStorage } from "../../../utils";
import actions from "../../../store/feed/actions";
import { routes } from "../../router/routes";
import { writerPlaceholder } from "../../../static";
import DeleteBlog from "../DeleteBlog";

const Feed = ({ blogs, fetchFeed, saveBlog, removeBlogFromFeed }) => {
  const [user, setUser] = useState(userStorage.getUser());

  useEffect(() => {
    fetchFeed();
  }, [fetchFeed]);

  const getMenuItems = (blog) => [
    {
      key: "view",
      label: (
        <Link to={routes.READ_BLOG(blog.blog_id)}>
          <Row align="middle">
            <EyeOutlined className="Blog-drop-item drop-view" />
            Read Blog
          </Row>
        </Link>
      ),
    },
    blog.author.name === user.name && {
      key: "edit",
      label: (
        <Link to={routes.EDIT_BLOG(blog.blog_id)}>
          <Row align="middle">
            <EditOutlined className="Blog-drop-item drop-edit" />
            Edit Blog
          </Row>
        </Link>
      ),
    },
    blog.author.name === user.name && {
      key: "delete",
      label: (
        <div
          onClick={() =>
            DeleteBlog(blog?.blog_id, blog?.title, () =>
              removeBlogFromFeed(blog?.blog_id)
            )
          }
        >
          <Row align="middle">
            <DeleteOutlined className="Blog-drop-item drop-delete" />
            Delete Blog
          </Row>
        </div>
      ),
    },
    {
      key: "share",
      label: (
        <div
          onClick={() => {
            const formattedTitle = blog?.title
              .toLowerCase()
              .replace(/\s+/g, "-"); // Convert to lowercase and replace spaces with hyphens

            const shareUrl = `${window.location.origin}/blog/${blog.blog_id}/${formattedTitle}`;
            navigator.clipboard.writeText(shareUrl);
            alert(`Blog link copied: ${shareUrl}`);
          }}
        >
          <Row align="middle">
            <ShareAltOutlined className="Blog-drop-item drop-share" />
            Share Blog
          </Row>
        </div>
      ),
    },
  ];

  const renderBlogs = (filteredBlogs) =>
    filteredBlogs?.map((blog) => (
      <article className="Blog" key={blog.blog_id}>
        <header className="Blog-Head">
          <div className="Blog-Head-left">
            <img
              src={blog.author.dp ?? defaultDp}
              onError={(e) => (e.target.src = writerPlaceholder)}
              alt="dp"
            />
            <span>
              <h3>{blog.title}</h3>
            </span>
          </div>
          <div className="Blog-Head-right">
            <Dropdown menu={{ items: getMenuItems(blog) }}>
              <MoreOutlined className="Blog-drop-icon" />
            </Dropdown>
          </div>
        </header>
        <div className="Blog-Body">
          {window.screen.width > 600 ? (
            <p>{ellipsis(blog.summary, 600)}</p>
          ) : (
            <p>{ellipsis(blog.summary, 300)}</p>
          )}
        </div>
      </article>
    ));

  const tabItems = [
    {
      key: "1",
      label: "Your Blogs",
      children: (
        <div className="Blogs">
          {" "}
          {blogs?.loading ? (
            <FeedSkeleton />
          ) : blogs?.blogs?.filter((blog) => blog.author.name === user.name)
              .length < 1 ? (
            <Row justify="center">
              <Tag color="warning">
                <Link to={routes.CREATE_BLOG}>
                  No Blogs Available. Publish one.
                </Link>
              </Tag>
            </Row>
          ) : (
            renderBlogs(
              blogs?.blogs.filter((blog) => blog.author.name === user.name)
            )
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Other Blogs",
      children: (
        <div className="Blogs">
          {" "}
          {blogs?.loading ? (
            <FeedSkeleton />
          ) : blogs?.blogs?.filter((blog) => blog.author.name !== user.name)
              .length < 1 ? (
            <Row justify="center">
              <Tag color="warning">No Blogs Available</Tag>
            </Row>
          ) : (
            renderBlogs(
              blogs?.blogs.filter((blog) => blog.author.name !== user.name)
            )
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="Feed">
      <Banner />
      <Navbar api createBlog search profile logout />
      <Tabs
        className="blog-tab"
        centered
        defaultActiveKey="1"
        items={tabItems}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth,
  blogs: state.feed,
});

export default connect(mapStateToProps, {
  fetchFeed: actions.fetchFeed,
  likeBlog: actions.likeBlog,
  saveBlog: actions.saveBlog,
  removeBlogFromFeed: actions.removeBlogFromFeed,
})(Feed);
