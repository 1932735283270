import "./index.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EditorState } from "draft-js";

import { Navbar } from "../../components";
import { routes } from "../../router/routes";
import {
  setTitle,
  setContent,
  handleBlog,
  resetStore,
} from "../../../store/blog/actions";

const CreateBlog = (props) => {
  const [submitType, setSubmitType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    props.setTitle("");
    props.setContent(EditorState.createEmpty());
    return () => {
      props.resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    props.handleBlog(null, "create", submitType, navigate);
  };

  return (
    <div className="blog-portal-wrapper">
      <Navbar feed profile logout />
      <div className="blog-portal">
        <Form
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
        >
          <h2 className="blog-portal-head">Create Blog</h2>

          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                min: 1,
                max: 40,
                message: "Title must be 1-40 characters",
                autoFill: false,
              },
            ]}
          >
            <Input
              onChange={(e) => props.setTitle(e.target.value)}
              size="large"
              placeholder="Type your title here..."
            />
          </Form.Item>

          <Form.Item label="Content">
            <Editor
              editorState={props.blog.content}
              onEditorStateChange={(val) => props.setContent(val)}
              wrapperClassName="richWrapper"
              editorClassName="richEditor"
              toolbarClassName="richToolbar"
              placeholder="Type your content here..."
            />
          </Form.Item>

          <Form.Item>
            <footer className="createBlog-footer">
              <div className="blog-portal-nav">
                <Button
                  className="archive-btn"
                  htmlType="submit"
                  loading={props.blog.submitType === "archive"}
                  disabled={props.blog.submitType === "publish"}
                  type="ghost"
                  size="large"
                  onClick={() => setSubmitType("archive")}
                >
                  Archive
                </Button>
                <Button
                  htmlType="submit"
                  loading={props.blog.submitType === "publish"}
                  disabled={props.blog.submitType === "archive"}
                  type="primary"
                  size="large"
                  onClick={() => setSubmitType("publish")}
                >
                  Publish
                </Button>
              </div>
              <Link to={routes.FEED}>Discard</Link>
            </footer>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { blog: state.blog };
};

export default connect(mapStateToProps, {
  resetStore,
  setTitle,
  setContent,
  handleBlog,
})(CreateBlog);
