import "./index.css";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, message } from "antd";
import { Link, useNavigate } from "react-router-dom";

import Layout from "./Layout";
import { icon } from "../../../static";
import actions from "../../../store/auth/actions";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth } from "../../../../../../api/firebase";
// import { getUsernamesAndEmailsService } from "../../../services/api/writer.api";

const { signup } = actions;

const Signup = () => {
  // const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [isAvailable, setIsAvailable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSubmitting } = useSelector((state) => state.auth);

  // const handleLiveCheck = (_, val) => {
  //   if (val === "") {
  //     setIsAvailable(false);
  //     return Promise.reject(new Error("Please provide an email"));
  //   } else if (emails.some((item) => item.email === val)) {
  //     setIsAvailable(false);
  //     return Promise.reject(new Error("Email already in use"));
  //   } else {
  //     setIsAvailable(true);
  //     return Promise.resolve();
  //   }
  // };

  const handleSignup = async (values) => {
    try {
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential?.user;
      console.log("User registered successfully:", user);

      const uid = user?.uid;
      dispatch(
        signup(
          values.first_name,
          values.last_name,
          values.email,
          values.password,
          uid,
          navigate
        )
      );
    } catch (error) {
      console.error("Unexpected error during registration:", error);
      message.error("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getUsernamesAndEmailsService().then((res) => {
  //     if (res.status === 200) {
  //       setEmails(res?.data);
  //       setLoading(false);
  //     } else {
  //       // message.error("Live email checking not available !");
  //     }
  //   });
  // }, []);

  return (
    <Layout className="Auth-right-signup">
      <div className="portal portal-signup">
        <h2 className="portal-head">
          <img src={icon} alt="icon" />
          <span>Signup</span>
        </h2>
        <Form layout="vertical" onFinish={handleSignup}>
          <Form.Item
            className="blog-input"
            label="First Name"
            name="first_name"
            rules={[
              { required: true, message: "Please provide your first name" },
            ]}
          >
            <Input size="large" placeholder="Enter your first name" />
          </Form.Item>
          <Form.Item
            className="blog-input"
            label="Last Name"
            name="last_name"
            rules={[
              { required: true, message: "Please provide your last name" },
            ]}
          >
            <Input size="large" placeholder="Enter your last name" />
          </Form.Item>
          <Form.Item
            className="blog-input"
            label="Email"
            name="email"
            rules={[
              { type: "email", message: "Please enter a valid email" },
              // { validator: handleLiveCheck },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your email"
              // onChange={handleLiveCheck}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            className="blog-input"
            label="Password"
            name="password"
            rules={[
              {
                min: 8,
                message: "Enter a strong password. Min 8 characters",
              },
              { required: true, message: "Password is required" },
            ]}
          >
            <Input.Password size="large" placeholder="Enter a password" />
          </Form.Item>

          <br />
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="portal-submit"
              loading={isSubmitting}
            >
              Signup
            </Button>
          </Form.Item>
          <Link to="/blog/login" className="portal-link">
            Already have an account ?
          </Link>
        </Form>
      </div>
    </Layout>
  );
};

export default Signup;
