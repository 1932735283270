import "./index.css";

import { Component, useEffect, useState } from "react";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { routes } from "../../router/routes";
import actions from "../../../store/auth/actions";
import { logger, userStorage } from "../../../utils";
import { config } from "../../../config";
import {
  ArrowLeftOutlined,
  EditOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { logout } = actions;

const Navbar = (props) => {
  const [user, setUser] = useState(userStorage.getUser());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout(navigate));
  };

  useEffect(() => {
    const handleScroll = () => {
      let navbar = document.getElementById("Navbar");
      try {
        let sticky = navbar?.offsetTop;
        if (window.pageYOffset > sticky) {
          navbar?.classList?.add("Navbar-fixed");
        } else {
          navbar?.classList?.remove("Navbar-fixed");
        }
      } catch (err) {
        logger.err(err);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`Navbar Navbar-${props.backBtn ? `btw` : `end`}`}
      id="Navbar"
    >
      {props.backBtn && (
        <div onClick={() => window.history.back()}>
          <nav>
            <span className="material-icons">
              <ArrowLeftOutlined className="antd-nav-icons" />
            </span>
            <p>Back</p>
          </nav>
        </div>
      )}
      <div className="Navbar-left">
        {props.api && user?.is_superuser && (
          <a href={`${config.BASE_API_URL}/admin`} rel="noopener">
            <nav>
              <span className="material-icons">construction</span>
              <p>Admin</p>
            </nav>
          </a>
        )}
        {props.createBlog && (
          <Link to={{ pathname: routes.CREATE_BLOG }}>
            <nav>
              <span className="material-icons">
                <EditOutlined className="antd-nav-icons" />
              </span>
              <p>New Blog</p>
            </nav>
          </Link>
        )}
        {/* {props.search && (
          <Link to={routes.SEARCH}>
            <nav>
              <span className="material-icons">search</span>
              <p>Search</p>
            </nav>
          </Link>
        )} */}
        {props.feed && (
          <Link to={routes.FEED}>
            <nav>
              <span className="material-icons">
                <HomeOutlined className="antd-nav-icons" />
              </span>
              <p>Feed</p>
            </nav>
          </Link>
        )}
        {props.profile && (
          <Link to={routes.PROFILE(user.writer_id)}>
            <nav>
              <span className="material-icons">
                <UserOutlined className="antd-nav-icons" />
              </span>
              <p>Profile</p>
            </nav>
          </Link>
        )}
        {props.settings && (
          <Link to={routes.SETTINGS}>
            <nav>
              <span className="material-icons">
                <SettingOutlined className="antd-nav-icons" />
              </span>
              <p>Settings</p>
            </nav>
          </Link>
        )}
        {props.logout && (
          <div to={routes.LOGOUT} onClick={handleLogout}>
            <nav>
              <span className="material-icons">
                <LogoutOutlined className="antd-nav-icons" />
              </span>
              <p>Logout</p>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
