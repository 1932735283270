import { Modal } from "antd";
import { store } from "../../../store";
import { alterBlog } from "../../../store/common/actions";

const AlterBlog = (pk, title, isPublished, callback) => {
  const { confirm } = Modal;
  return confirm({
    className: "blog-confirm-archive",
    title: `${isPublished ? "Archive" : "Publish"} '${title}'`,
    content: `Are you sure you want to ${
      isPublished ? "archive" : "publish"
    } the blog '${title}'.`,
    centered: true,
    okText: `${isPublished ? "Archive" : "Publish"}`,
    okType: "primary",
    okButtonProps: {
      loading: store.getState().common.altBlogPk === pk,
    },
    cancelButtonProps: {
      type: "text",
    },
    onOk: () => store.dispatch(alterBlog(pk, isPublished, callback)),
  });
};

export default AlterBlog;
