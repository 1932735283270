import "./index.css";
import icon from "../../../../../../assets/images/SVG/LogoWithName.svg";
import iconInverse from "../../../../../../assets/images/SVG/Text copy 2.svg";
import { useLocation, useNavigate } from "react-router";

const Banner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isFeedPage = location.pathname.includes("feed");
  return (
    <div className="banner">
      <img
        onClick={() => {
          if (!isFeedPage) {
            navigate("/");
          }
        }}
        src={isFeedPage ? iconInverse : icon}
        alt="icon"
      />
    </div>
  );
};

export default Banner;
