import "./index.css";
import { useEffect, useState } from "react";
import { Button, Popover } from "antd";
import { AccountCard, Navbar } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { writerPlaceholder } from "../../../static";
import { routes } from "../../router/routes";
import { getBlog, handleLike, handleSave } from "../../../store/blog/actions";
import { userStorage } from "../../../utils";
import { ReadBlogSkeleton } from "../../skeletons";
import DeleteBlog from "../DeleteBlog";
import AlterBlog from "../AlterBlog";
import defaultDp from "../../../../../../assets/images/profile.png";
import dayjs from "dayjs";

const ReadBlog = () => {
  const [user, setUser] = useState(userStorage.getUser());
  const [isSaved, setIsSaved] = useState(false);
  const { blogId } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog.blog);
  const loading = useSelector((state) => state.blog.loading);

  useEffect(() => {
    dispatch(getBlog(blogId));
    setUser(userStorage.getUser());
  }, [dispatch, blogId]);

  useEffect(() => {
    setIsSaved(blog?.isSaved); // Initialize the isSaved state when blog is fetched
  }, [blog]);
  const handleAlterBlog = () => {
    AlterBlog(blog?.blog_id, blog?.title, blog?.isPublished, () =>
      dispatch(getBlog(blogId))
    );
  };

  const handleDeleteBlog = () => {
    DeleteBlog(blog?.blog_id, blog?.title, () => history(routes.FEED));
  };

  const handleSaveBlog = () => {
    dispatch(handleSave(blogId));
    setIsSaved((prev) => !prev);
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format("MMMM DD, YYYY");
  };

  return (
    <div className="Feed ReadBlog">
      <Navbar backBtn feed profile logout />
      <div className="Blogs">
        {loading ? (
          <ReadBlogSkeleton />
        ) : (
          <article className="Blog">
            <header className="Blog-Head">
              <div className="Blog-Head-left">
                <img
                  src={blog?.author?.dp ?? defaultDp}
                  onError={(e) => (e.target.src = writerPlaceholder)}
                  alt="dp"
                />
                <span>
                  <h3>{blog?.title}</h3>
                  {/* <Popover
                    content={
                      <AccountCard
                        img={blog?.author?.dp}
                        username={blog?.author?.username}
                        name={blog?.author?.name}
                      />
                    }
                    style={{ padding: 0 }}
                  >
                    <Link to={routes.PROFILE(blog?.author?.username)}>
                      {blog?.author?.username}
                    </Link>
                  </Popover> */}
                </span>
              </div>
              <span className="published-date">
                {formatDate(blog?.pub_date)}
              </span>
            </header>
            <div
              className="Blog-Body"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            ></div>
            {blog?.author?.name !== user.name ? (
              <div className="Blog-Nav">
                {/* <div onClick={() => dispatch(handleLike(blog?.blog_id))}>
                  <Stud
                    type={blog.likesList}
                    icon="favorite"
                    theme="#ff6347"
                    count={blog.noOfLikes}
                    active={blog.isLiked}
                  />
                </div> */}

                <div onClick={handleSaveBlog}>
                  <Button type="text" className="blog-btn edit">
                    {isSaved ? "Unsave" : "Save"}
                  </Button>
                </div>
              </div>
            ) : (
              <footer className="Blog-Nav">
                {/* <div onClick={() => dispatch(handleLike(blog?.blog_id))}>
                  <Stud
                    type={blog.likesList}
                    icon="favorite"
                    theme="#ff6347"
                    count={blog.noOfLikes}
                    active={blog.isLiked}
                  />
                </div> */}
                <Link to={routes.EDIT_BLOG(blog.blog_id)}>
                  <Button type="text" className="blog-btn edit">
                    Edit
                  </Button>
                </Link>
                <div onClick={handleAlterBlog}>
                  <Button type="text" className="blog-btn archive">
                    {blog?.isPublished ? "Archive" : "Publish"}
                  </Button>
                </div>
                <div onClick={handleDeleteBlog}>
                  <Button type="text" className="blog-btn delete">
                    Delete
                  </Button>
                </div>
              </footer>
            )}
          </article>
        )}
      </div>
    </div>
  );
};

export default ReadBlog;
