import React from "react";
import { Route, Routes } from "react-router-dom";

// Home
import Login from "../pages/Home/Login";
import Signup from "../pages/Home/Signup";

// General
import Feed from "../pages/Feed";
import Search from "../pages/Search";

// Writer
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";

// Blog
import CreateBlog from "../pages/CreateBlog";
import EditBlog from "../pages/EditBlog";
import ReadBlog from "../pages/ReadBlog";

// Results
import Error404 from "../pages/Results/404";
import SignupSuccess from "../pages/Results/SignupSuccess";
import EmailSuccess from "../pages/Results/EmailSuccess";
import EmailFailure from "../pages/Results/EmailFailure";

import { routes } from "./routes";

function Router() {
  console.log("testtttt2222");

  return (
    // <div className="Keyblogs">

    // <Routes>
    <Routes>
      <Route index element={<Login />} />
      <Route exact path={routes.SIGNUP} element={<Signup />} />
      {/* END: Home */}

      {/* START: General */}
      <Route exact path={routes.FEED} element={<Feed />} />
      <Route exact path={routes.SEARCH} element={<Search />} />
      {/* END: General */}

      {/* START: Writer */}
      <Route exact path={routes.PROFILE(":userId")} element={<Profile />} />
      <Route exact path={routes.SETTINGS} element={<Settings />} />
      {/* END: Writer */}

      {/* START: Blog */}
      <Route path={routes.CREATE_BLOG} element={<CreateBlog />} />
      <Route exact path={routes.READ_BLOG(":blogId")} element={<ReadBlog />} />
      <Route exact path={routes.EDIT_BLOG(":blogId")} element={<EditBlog />} />
      {/* END: Blog */}

      {/* START: Results */}
      <Route exact path={routes.SIGNUP_SUCCESS} element={<SignupSuccess />} />
      <Route
        exact
        path={routes.EMAIL_SUCCESS(":userId")}
        element={<EmailSuccess />}
      />
      <Route exact path={routes.EMAIL_FAILURE} element={<EmailFailure />} />
      <Route path="*" element={<Error404 />} />
      {/* END: Results */}
      {/* </Switch> */}
    </Routes>
    // </Routes>
    // </div>
  );
}

export default Router;
