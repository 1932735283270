import "./PublicReadBlogs.styles.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogById } from "../../../store/blog/actions";
import { ReadBlogSkeleton } from "../../skeletons";
import defaultDp from "../../../../../../assets/images/profile.png";
import dayjs from "dayjs";

const PublicReadBlog = () => {
  const { blogId } = useParams();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog.blog);
  const loading = useSelector((state) => state.blog.loading);

  useEffect(() => {
    dispatch(getBlogById(blogId));
  }, [dispatch, blogId]);

  const formatDate = (dateString) => {
    return dayjs(dateString).format("MMMM DD, YYYY");
  };

  return (
    <div className="publicReadBlog-container">
      <div className="blogs">
        {loading ? (
          <ReadBlogSkeleton />
        ) : blog.blog_id ? (
          <article className="blog">
            <h1 className="blog-title">{blog?.title}</h1>
            <header className="Blog-Head">
              <div className="Blog-Head-left">
                <img
                  height="60px"
                  width="60px"
                  src={blog?.author?.dp ?? defaultDp}
                  onError={(e) => (e.target.src = defaultDp)}
                  alt="dp"
                />
                <span className="blog-details">
                  <h3>{blog?.author?.name}</h3>
                  <span className="published-date">
                    {formatDate(blog?.pub_date)}
                  </span>
                </span>
              </div>
            </header>
            <div className="blog-Body">
              <div
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              ></div>
            </div>
          </article>
        ) : (
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            No Blog Found
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicReadBlog;
