import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, message, Spin, notification } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../../../api/firebase.js";
import actions from "../../../store/auth/actions";
import appleImg from "../../../../../../assets/images/SVG/apple-white.svg";
import googleImg from "../../../../../../assets/images/SVG/google-logo.svg";
import { icon } from "../../../static";
import Layout from "./Layout.js";
import { UserAuth } from "../../../../../../context/AuthContext.jsx";
import { useMutation } from "react-query";
import { handleLogin } from "../../../../../../components/Forms/LoginForm/LoginForm.jsx";
import { createWriter } from "../../../services/api/blog.api.js";
import { userStorage } from "../../../utils/index.js";
import { actionCreators } from "../../../store/auth/actions/creators.js";
import { handleRegistration } from "../../../../../../components/Forms/RegisterForm/RegisterForm.jsx";

const BlogLogin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { googleSignIn, appleSignIn } = UserAuth();
  const [loadingGoogleSignIn, setLoadingGoogleSignIn] = useState(false);

  const handleBlogLogin = async (values) => {
    const { email, password } = values;
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = userCredential.user.uid;

      dispatch(actions.login(uid, navigate));

      message.success("Logged in successfully!");
    } catch (error) {
      console.error("Login error:", error);
      // Show error message based on Firebase error codes
      if (error.code === "auth/wrong-password") {
        message.error("Incorrect password. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        message.error("User not found. Please check your email.");
      } else {
        message.error("Login failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const socialLoginMutation = useMutation(
    (values) => handleLogin(values, values.type),
    {
      onSuccess: async () => {
        console.log("Google Login successful!");
        // Fetch current user details
        const writerRes = await createWriter();
        console.log(writerRes);

        if (writerRes?.status === 201 || writerRes?.status === 200) {
          userStorage.setUser(writerRes?.data);
          dispatch(actionCreators.setUserId(writerRes?.data?.writer_id));
          navigate("/blog/feed");
          notification.info({
            message: `Welcome back, ${writerRes?.data?.name}`,
          });
        } else {
          // message.error("Failed to fetch writer information.");
          dispatch(actionCreators.loginError());
        }
      },
      onError: (error) => {
        console.error("Google Login error:", error);
        const errorMessage = error.message;
        console.log(errorMessage);
        message.error(errorMessage || "An error occurred during Google login");
      },
    }
  );

  const handleGoogleButtonClick = async () => {
    setLoadingGoogleSignIn(true);
    try {
      const userData = await googleSignIn();
      console.log({ userData });
      const email = userData?.user?.email;
      const profilePicture = userData?._tokenResponse?.photoUrl;
      const splitName = userData?._tokenResponse?.displayName?.split(" ");
      const firstName = splitName[0];
      const lastName = splitName.slice(1).join(" ");
      const userId = userData?.user?.uid;
      const secretKey = "yourSecretKey";
      const uid = userId + secretKey;
      const loginValues = {
        uid: uid,
        login_type: "Google",
      };
      const registerValues = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        uid: uid,
        password: uid,
        profile_picture_url: profilePicture,
      };
      const loginResult = await socialLoginMutation.mutateAsync(loginValues);
      if (loginResult?.success === false) {
        await handleRegistration(registerValues, "google");
        await socialLoginMutation.mutateAsync(loginValues);
      }
    } catch (err) {
      console.error(err);
      navigate("/blog/login");
      setLoadingGoogleSignIn(false);
    } finally {
      setLoadingGoogleSignIn(false);
    }
  };

  const handleAppleButtonClick = async () => {
    setLoadingGoogleSignIn(true);
    try {
      const userData = await appleSignIn();
      console.log({ userData });
      const email = userData?._tokenResponse?.email;
      const userId = userData?.user?.uid;
      const secretKey = "yourSecretKey";
      const uid = userId + secretKey;

      let firstName = "";
      let lastName = "";

      if (userData?.user?.displayName) {
        const splitName =
          userData?.user?.displayName?.trim().split(/\s+/) || [];
        firstName = splitName[0] || "";
        lastName = splitName?.slice(1).join(" ") || "";
      } else {
        const emailNamePart = email.split("@")[0];
        const nameLength = emailNamePart?.length;
        const halfLength = Math.ceil(nameLength / 2);
        firstName = emailNamePart?.substring(0, halfLength);
        lastName = emailNamePart?.substring(halfLength);
      }

      const values = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        uid: uid,
        password: uid,
      };

      const loginValues = {
        uid: uid,
        login_type: "Apple",
      };
      const loginResult = await socialLoginMutation.mutateAsync(loginValues);
      if (loginResult?.success === false) {
        await handleRegistration(values, "apple");
        await socialLoginMutation.mutateAsync(loginValues);
      }
    } catch (err) {
      console.error(err);
      navigate("/blog/login");
      setLoadingGoogleSignIn(false);
    } finally {
      setLoadingGoogleSignIn(false);
    }
  };

  return (
    <Layout>
      <div className="portal-login">
        {loadingGoogleSignIn ? (
          <div className="loading-spinner">
            <Spin size="large" />
          </div>
        ) : (
          <div className="portal">
            <h2 className="portal-head">
              <img src={icon} alt="icon" />
              <span>Login</span>
            </h2>
            <Form onFinish={handleBlogLogin} layout="vertical">
              <Form.Item
                className="blog-input"
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Enter your email to login" },
                  { type: "email", message: "Enter a valid email" },
                ]}
              >
                <Input size="large" placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                className="blog-input"
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Enter your username to login" },
                ]}
              >
                <Input.Password size="large" placeholder="Enter Password" />
              </Form.Item>
              <br />
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="portal-submit"
                  loading={loading}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <div className="social-buttons">
              <Button
                className="social-button"
                onClick={handleAppleButtonClick}
              >
                <img src={appleImg} alt="apple" width="33px" height="33px" />
              </Button>
              <Button
                className="social-button"
                onClick={handleGoogleButtonClick}
              >
                <img src={googleImg} alt="google" width="25px" height="25px" />
              </Button>
            </div>
            <Link to="/blog/signup" className="portal-link">
              Create an account ?
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BlogLogin;
