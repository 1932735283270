import "./index.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import actions from "../../../store/profile/actions";
import { writerPlaceholder } from "../../../static";
import { routes } from "../../router/routes";
import { userStorage } from "../../../utils";
import { followOrUnfollow } from "../../../store/common/actions";
import defaultDp from "../../../../../../assets/images/profile.png";

const ProfileTab = () => {
  const [user] = useState(userStorage.getUser());
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const setTab = (tabIndex) => {
    dispatch(actions.setTab(tabIndex));
  };

  return (
    <div className="profile-nav">
      <menu>
        <nav
          onClick={() => setTab(3)}
          className={profile.selectedTab === 3 ? "active" : ""}
        >
          <div className="profile-nav-text">
            <p>Published &nbsp; </p>
            <small>({profile.publishedBlogs?.length})</small>
          </div>
        </nav>
        {profile.isUser && (
          <>
            <nav
              onClick={() => setTab(4)}
              className={profile.selectedTab === 4 ? "active" : ""}
            >
              <div className="profile-nav-text">
                <p>Archived &nbsp; </p>
                <small>({profile.archivedBlogs?.length})</small>
              </div>
            </nav>
            <nav
              onClick={() => setTab(5)}
              className={profile.selectedTab === 5 ? "active" : ""}
            >
              <div className="profile-nav-text">
                <p>Saved &nbsp; </p>
                <small>({profile.savedBlogs?.length})</small>
              </div>
            </nav>
          </>
        )}
      </menu>
      <div className="profile-content">
        <div className="prof-tab-cards">
          {
            //   START: Published Blogs Tab
            profile.selectedTab === 3
              ? profile.publishedBlogs.map((blog) => (
                  <Link to={routes.READ_BLOG(blog.blog_id)} key={blog.blog_id}>
                    <div className="prof-tab-card">
                      <div className="prof-tab-card-left">
                        <img
                          src={blog.author.dp ?? defaultDp}
                          onError={({ target }) =>
                            (target.src = writerPlaceholder)
                          }
                          alt="authorDP"
                        />
                        <div className="prof-tab-card-content">
                          <h3>{blog.title}</h3>
                          <time>{"Few days ago"}</time>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              : profile.selectedTab === 4
              ? profile.archivedBlogs.map((blog) => (
                  <Link to={routes.READ_BLOG(blog.blog_id)} key={blog.blog_id}>
                    <div className="prof-tab-card">
                      <div className="prof-tab-card-left">
                        <img
                          src={blog.author.dp ?? defaultDp}
                          onError={({ target }) =>
                            (target.src = writerPlaceholder)
                          }
                          alt="authorDP"
                        />
                        <div className="prof-tab-card-content">
                          <h3>{blog.title}</h3>
                          <time>{"Few days ago"}</time>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              : profile.selectedTab === 5
              ? profile.savedBlogs.map((blog) => (
                  <Link to={routes.READ_BLOG(blog.blog_id)} key={blog.blog_id}>
                    <div className="prof-tab-card">
                      <div className="prof-tab-card-left">
                        <img
                          src={blog.author.dp ?? defaultDp}
                          onError={({ target }) =>
                            (target.src = writerPlaceholder)
                          }
                          alt="authorDP"
                        />
                        <div className="prof-tab-card-content">
                          <h3>{blog.title}</h3>
                          <time>{"Few days ago"}</time>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              : setTab(3) // Default tab
          }
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
