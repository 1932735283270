import { message, notification } from "antd";
import Cookies from "js-cookie";
import { actionCreators } from "./creators";
import { userStorage, logger } from "../../../utils";
import { routes } from "../../../app/router/routes";
import { getWriterService } from "../../../services/api/writer.api";
import api from "../../../../../../api/api";
import { createWriter } from "../../../services/api/blog.api";

const actions = {
  signup:
    (first_name, last_name, email, password, uid, navigate) => (dispatch) => {
      dispatch(actionCreators.signupBegin());
      api
        .registerUser({
          first_name,
          last_name,
          email,
          uid,
          password,
          login_type: "Email",
        })
        .then((res) => {
          if (res?.status === 201) {
            //   dispatch(actionCreators.signupSuccess());
            //   message.success("Account created ! Verify your email to continue");
            //   navigate(routes.SIGNUP_SUCCESS);
            // } else if (res?.status === 204) {
            //   dispatch(actionCreators.signupSuccess());
            navigate("/blog/login");
            message.success("Account created ! Login to continue");
          } else {
            dispatch(actionCreators.signupError());
            message.error("Some error occured");
          }
        })
        .catch((err) => {
          dispatch(actionCreators.loginError("Some error occured"));
          logger.err(err, "Some error occured");
        });
    },

  autoLogin: (pk) => (dispatch) => {
    getWriterService(pk).then((res) => {
      if (res?.status === 200) {
        dispatch(actionCreators.loginSuccess(res?.data));
        userStorage.setUser(res?.data);
        dispatch(actionCreators.setUserId(res?.data?.pk));
      }
    });
  },

  login: (uid, navigate) => (dispatch) => {
    dispatch(actionCreators.loginBegin());
    api
      .loginUser({ uid })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(actionCreators.loginSuccess(res?.data));
          createWriter().then((writerRes) => {
            console.log({ writerRes });
            if (writerRes?.status === 201 || writerRes?.status === 200) {
              userStorage.setUser(writerRes?.data);
              dispatch(actionCreators.setUserId(writerRes?.data?.writer_id));
              navigate("/blog/feed");
              notification.info({
                message: `Welcome back, ${writerRes?.data?.name}`,
              });
            } else {
              message.error("Failed to fetch writer information.");
              dispatch(actionCreators.loginError());
            }
          });
        } else {
          dispatch(actionCreators.loginError());
          message.error("Invalid email or password");
        }
      })
      .catch((err) => {
        dispatch(actionCreators.loginError("Some error occured"));
        logger.err(err, "Some error occured");
      });
  },

  logout: (naviagte) => (dispatch) => {
    // const user = userStorage.getUser();
    api
      .logoutUser()
      .then(() => {
        naviagte("/blog/login");
        dispatch(actionCreators.logout());
        dispatch(actionCreators.setUserId(-1));
        userStorage.setUser({});
        Cookies.remove("csrftoken");
        Cookies.remove("sessionid");
      })
      .catch((err) => {
        logger.err(err, "Some error occured");
      });
  },
};

export default actions;
