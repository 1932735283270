export const initialState = {
  loading: true,
  isUser: false,
  selectedTab: 3,
  followingUserPk: -1,
  data: {},
  followers: [],
  following: [],
  publishedBlogs: [],
  archivedBlogs: [],
  savedBlogs: [],
};
