import { Link } from "react-router-dom";
import logo from "../../assets/images/SVG/LogoWithName.svg";
import messageIcon from "../../assets/images/SVG/message.svg";
import "./Footer.styles.scss";

import React from "react";
import {
  CaretRightFilled,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Divider } from "antd";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="about-links">
        <div className="section logo-description">
          <img src={logo} className="logo" alt="logo" />
          <span className="description">
            We make co-living easier! Here you can search for new tenants,
            assign and track chores, manage expenses and do so much more -
            all in one place.
          </span>
        </div>
        <div className="section contact-container">
          <h1 className="title">Contact</h1>
          <div className="support">
            <img
              className="message-icon"
              height="15px"
              width="15px"
              src={messageIcon}
              alt="message"
            />
            <span className="email">support@cohubitate.com</span>
          </div>
        </div>
        <div className="section links-container">
          <h1 className="title">Quick Links</h1>
          <div className="links">
            <Link to="/support">
              <CaretRightFilled />
              <span>Support</span>
            </Link>
            <Link to="/privacy">
              <CaretRightFilled />
              <span>Privacy</span>
            </Link>
            <Link to="/blog/login">
              <CaretRightFilled />
              <span>Blogs</span>
            </Link>
          </div>
          <div className="section2 contact2-container">
            <h1 className="title">Contact</h1>
            <div className="support">
              <img
                className="message-icon"
                height="15px"
                width="15px"
                src={messageIcon}
                alt="message"
              />
              <span className="email">support@cohubitate.com</span>
            </div>
          </div>
        </div>
      </div>
      <Divider className="footer-divider" />
      <div className="copyright-social-section">
        <span className="copyright">Copyright © . All rights reserved.</span>
        <div className="icons-container">
          <InstagramOutlined />
          <TwitterOutlined />
          <LinkedinOutlined />
        </div>
      </div>
    </div>
  );
};

export default Footer;
