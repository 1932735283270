import { Modal } from "antd";
import { store } from "../../../store";
import { deleteBlog } from "../../../store/common/actions";

const DeleteBlog = (pk, title, callback) => {
  const { confirm } = Modal;
  return confirm({
    className: "blog-confirm",
    title: `Delete '${title}'`,
    content: `Are you sure you want to delete the blog '${title}'. This action cannot be reversed.`,
    centered: true,
    okText: `Delete`,
    okType: "danger",
    okButtonProps: {
      loading: store.getState().common.dltBlogPk === pk,
    },
    onOk: () => store.dispatch(deleteBlog(pk, callback)),
  });
};

export default DeleteBlog;
